<template>
  <div id="common">
    <!-- <formV1 ref="uform" :model="params">
      <formItemV1 prop="name" childrenName="uinput">
        <input type="text" v-model="params.name" @blur="onBlur" />
      </formItemV1>
    </formV1> -->
    <scorllview @onRefresh="onRefresh" @onReload="onReload">
      <div
        style="
          width: 100px;
          height: 100px;
          outline: 1px solid black;
          margin: 10px auto;
        "
        v-for="(item, index) in list"
        :key="index"
      >
        {{ index }}
      </div>
    </scorllview>
  </div>
</template>
<script>
import formV1 from "@C/form/formV1";
import formItemV1 from "@C/formItem/formItemV1";
import scorllview from "@/uicomponent/scrollView/scorllview";
export default {
  name: "uinput",
  components: {
    formV1,
    formItemV1,
    scorllview,
  },
  data() {
    return {
      params: {
        name: "",
      },
      rules: {
        name: [
          {
            type: "string",
            required: true,
          },
        ],
      },
      loadstate: 0,
      list: [],
      defaultList: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    };
  },
  mounted() {
    // this.$refs.uform.setRules(this.rules);
  },
  methods: {
    // onChange(e) {
    //   this.dispatch("formItemV1", "on-form-item-child-change", e);
    // },
    onBlur(e) {
      console.log("111");
      this.$refs.uform.validate((val) => {
        console.log(val);
      });
    },
    onRefresh(done) {
      setTimeout(() => {
        this.list = this.defaultList;
        done();
      }, 1000);
    },
    /**
     * step 当前加载结束
     * over 没有更多数据了
     */
    onReload(step, over) {
      console.log("上拉加载");
      setTimeout(() => {
        this.list = [...this.list, ...this.defaultList];
        if (this.list.length > 100) {
          over();
        } else {
          step();
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
#common {
  width: 100%;
  height: 100%;
}
</style>
